.video-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  
  .video-wrapper video {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 50px);
    object-fit: contain;
  }
  
  /* Host video */
  .host-video {
    z-index: 10;
    position: relative;
  }
  
  /* Viewer video */
  .viewer-video {
    pointer-events: auto !important;
  }
  
  /* Streaming controls */
  .streaming-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 2rem;
  }
  
  .streaming-controls h3 {
    margin-top: 0;
    margin-bottom: 1rem;
    font-size: 1.5rem;
    color: white;
  }
  
  .file-info {
    margin-bottom: 1.5rem;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.1);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  
  .streaming-controls .primary-button {
    background-color: #e74c3c;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    font-size: 1.2rem;
    cursor: pointer;
    margin-bottom: 1rem;
    transition: background-color 0.2s;
  }
  
  .streaming-controls .primary-button:hover {
    background-color: #c0392b;
  }
  
  .streaming-controls .primary-button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .hint {
    opacity: 0.7;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 1rem;
  }
  
  .error-message {
    color: #e74c3c;
    background-color: rgba(231, 76, 60, 0.2);
    padding: 0.5rem 1rem;
    border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  
  /* Host controls */
  .host-controls {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    z-index: 20;
  }
  
  .control-buttons {
    display: flex;
    gap: 10px;
  }
  
  .control-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .control-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .theater-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
  }
  
  .stop-button {
    background-color: rgba(231, 76, 60, 0.8);
  }
  
  .stop-button:hover {
    background-color: rgba(231, 76, 60, 1);
  }
  
  .streaming-status {
    background-color: rgba(52, 152, 219, 0.8);
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 5px;
  }
  
  .streaming-status:before {
    content: '';
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: white;
    animation: pulse 1.5s infinite;
  }
  
  /* Viewer controls */
  .viewer-controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 20;
    display: flex;
    gap: 8px;
  }
  
  .viewer-message {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 8px;
    z-index: 10;
    font-size: 0.9rem;
  }
  
  .waiting-for-video {
    color: white;
    text-align: center;
    padding: 2rem;
  }
  
  .waiting-for-video .small {
    font-size: 0.8rem;
    opacity: 0.7;
    margin-top: 1rem;
  }
  
  /* Connection status indicator */
  .connection-status {
    position: absolute;
    top: 15px;
    left: 15px;
    z-index: 30;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 5px;
    padding: 8px 12px;
  }
  
  .status-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    font-size: 14px;
  }
  
  .status-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
  
  .status-dot.disconnected {
    background-color: #95a5a6;
  }
  
  .status-dot.connecting {
    background-color: #f39c12;
    animation: pulse 1s infinite;
  }
  
  .status-dot.buffering {
    background-color: #3498db;
    animation: pulse 1s infinite;
  }
  
  .status-dot.error {
    background-color: #e74c3c;
    animation: pulse 1s infinite;
  }
  
  .status-dot.ready {
    background-color: #2ecc71;
  }
  
  /* Viewer stats panel */
  .viewer-stats-panel {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px;
    border-radius: 5px;
    max-width: 250px;
    z-index: 20;
  }
  
  .viewer-stats-panel h4 {
    margin-top: 0;
    margin-bottom: 8px;
    font-size: 14px;
    color: white;
  }
  
  .viewer-stats-panel ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .viewer-stat {
    font-size: 12px;
    padding: 4px 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    display: flex;
    justify-content: space-between;
  }
  
  .viewer-stat.buffering {
    color: #f39c12;
  }
  
  .connection-quality {
    margin-left: 8px;
    padding: 2px 6px;
    border-radius: 10px;
    font-size: 10px;
  }
  
  .connection-quality.good {
    background-color: #2ecc71;
    color: white;
  }
  
  .connection-quality.fair {
    background-color: #f39c12;
    color: white;
  }
  
  .connection-quality.poor {
    background-color: #e74c3c;
    color: white;
  }
  
  .connection-quality.unknown, 
  .connection-quality.connecting {
    background-color: #95a5a6;
    color: white;
  }
  
  @keyframes pulse {
    0% { opacity: 0.4; }
    50% { opacity: 1; }
    100% { opacity: 0.4; }
  }