.home-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .tabs {
    display: flex;
    margin-bottom: 2rem;
    border-bottom: 1px solid #eee;
  }
  
  .tabs button {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    opacity: 0.7;
    transition: opacity 0.2s;
  }
  
  .tabs button.active {
    opacity: 1;
    border-bottom: 2px solid #3498db;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .form-group input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .file-input {
    position: relative;
    margin-bottom: 1.5rem;
  }
  
  .file-input input[type="file"] {
    margin-bottom: 0.5rem;
  }
  
  .file-name {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.25rem;
  }
  
  .file-size {
    font-size: 0.9rem;
    color: #666;
    margin-top: 0.25rem;
  }
  
  .primary-button {
    background-color: #3498db;
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
    width: 100%;
  }
  
  .primary-button:hover {
    background-color: #2980b9;
  }
  
  .primary-button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  .error-message {
    color: #e74c3c;
    margin-top: 1rem;
    font-size: 0.9rem;
  }
  
  .progress-container {
    margin: 1rem 0;
    text-align: center;
    color: #3498db;
  }
  
  .info-text {
    font-size: 0.9rem;
    color: #666;
    margin-top: 1rem;
    font-style: italic;
  }